import 'ol/ol.css';
import { Map, View } from 'ol';
import { fromLonLat, transformExtent, useGeographic } from 'ol/proj.js';
import {
  defaults as defaultControls,
  ScaleLine,
  Attribution,
} from 'ol/control.js';
import { KeyboardPan, KeyboardZoom } from 'ol/interaction';
import { watch } from 'vue';
import { getForViewAndSize, intersects } from 'ol/extent.js';
import { createXYZ } from 'ol/tilegrid.js';
import {
  baselayerGroup,
  fachkarteGroup,
  fachkarteCenter,
  fachkarteZoom,
  xyzQuery,
} from './useLayers.js';
import { REFERENCE_SCREEN_SIZE } from '../constants.js';

useGeographic();

export const map = new Map({
  keyboardEventTarget: document,
  layers: [baselayerGroup, fachkarteGroup],
  controls: defaultControls({
    zoom: false,
    rotate: false,
    attribution: false,
  }),
});

map.on('moveend', () => {
  const view = map.getView();
  const center = view.getCenter();
  xyzQuery.value = {
    x: center[0].toFixed(5),
    y: center[1].toFixed(5),
    z: view.getZoom().toFixed(5),
  };
});

map.addInteraction(new KeyboardPan());
map.addInteraction(new KeyboardZoom());

const attributionControl = new Attribution({ collapsible: false });
map.addControl(attributionControl);
const scaleLineControl = new ScaleLine({ className: 'ol-scale-line' });
map.addControl(scaleLineControl);

function fitToFachkarte(center, zoom) {
  const view = map.getView();
  const fachkarteExtent = transformExtent(
    getForViewAndSize(
      fromLonLat(center),
      view.getResolutionForZoom(zoom),
      view.getRotation(),
      map.getSize()
    ),
    'EPSG:3857',
    'EPSG:4326'
  );
  if (
    zoom < view.getZoom() ||
    !intersects(view.calculateExtent(map.getSize()), fachkarteExtent)
  ) {
    view.animate({ center, zoom, duration: 500 });
  }
}

watch([fachkarteCenter, fachkarteZoom], ([center, zoom]) => {
  if (!center || zoom === undefined) {
    return;
  }
  const resolutions = createXYZ({ tileSize: 512 }).getResolutions();
  const extent = transformExtent(
    getForViewAndSize(
      fromLonLat(center),
      resolutions[Math.floor(zoom)],
      0,
      REFERENCE_SCREEN_SIZE
    ),
    'EPSG:3857',
    'EPSG:4326'
  );
  const currentView = map.getView();
  const newView = new View({
    center:
      currentView.getCenter() ||
      [xyzQuery.value.x, xyzQuery.value.y].map(Number),
    zoom: currentView.getZoom() || Number(xyzQuery.value.z),
    rotation: currentView.getRotation(),
    resolutions,
    extent,
    showFullExtent: true,
  });
  map.setView(newView);

  if (!newView.isDef()) {
    if (map.getSize()) {
      fitToFachkarte(center, zoom);
    } else {
      map.once('change:size', () => fitToFachkarte(center, zoom));
    }
  }
});

/**
 * @param {{attribution?: import('vue').Ref<HTMLDivElement>, scaleLine?: import('vue').Ref<HTMLDivElement>}} [param0]
 * @returns
 */
export function useMap({ attribution, scaleLine } = {}) {
  if (attribution) {
    const unwatchAttribution = watch(attribution, (attributionTarget) => {
      unwatchAttribution();
      attributionControl.setTarget(attributionTarget);
      attributionControl.setMap(map);
    });
  }
  if (scaleLine) {
    const unwatchScaleLine = watch(scaleLine, (scaleLineTarget) => {
      unwatchScaleLine();
      scaleLineControl.setTarget(scaleLineTarget);
      scaleLineControl.setMap(map);
    });
  }
  return { map, xyzQuery };
}
