import { ref, shallowRef, watch } from 'vue';
import { WKT } from 'ol/format.js';
import { Feature } from 'ol';
import { Interaction, Modify } from 'ol/interaction.js';
import { Vector as VectorSource } from 'ol/source.js';
import VectorLayer from 'ol/layer/Vector.js';
import { dataURLToBlob } from 'blob-util';
import { Parser } from '@json2csv/plainjs';
import { Point } from 'ol/geom.js';
import { map } from './useMap.js';
import { strapiSources } from './useLayers.js';
import {
  fetchWithToken,
  requestPasswordless,
  UserData,
} from './useUserManage.js';

import { API_BASE } from '../constants.js';

/**
 * @typedef {Object} EditorModeData
 * @property {(feature: Feature, editAction: EditAction) => boolean} canEdit
 * @property {(feature: Feature) => void} editFeature
 * @property {import('vue').Ref<Object>} editorMode
 * @property {import('vue').Ref<Object<string, *>>} editableObject
 * @property {import('vue').Ref<string>} editableObjectId
 * @property {import('vue').Ref<Array<File|Blob>>} documents
 * @property {(string?) => Promise<boolean>} saveItem
 * @property {() => Promise<boolean>} deleteItem
 * @property {() => void} verifyNextItem
 * @property {() => Promise<boolean>} csvDownload
 */

/** @typedef {'add'|'edit'|'delete'|''} EditAction */

/**
 * @typedef {Object} EditorMode
 * @property {EditAction} mode
 * @property {boolean} snack
 */

const wktFormat = new WKT();
/** @type {Object<string, EditorModeData>} */
const composableData = {};

const editActionProperty = {
  edit: '_userCanUpdate',
  delete: '_userCanDelete',
};

/**
 * @param {string} source
 * @returns {EditorModeData}
 */
export function useEditorMode(source) {
  if (!composableData[source]) {
    /** @type {import('vue').Ref<EditorMode>} */
    const editorMode = ref({ mode: '', snack: false });

    /** @type {import('vue').Ref<Object<string, *>>} */
    const editableObject = ref(null);
    const editableObjectId = ref();

    const modifySource = new VectorSource();
    const modifyLayer = new VectorLayer({
      source: modifySource,
      style: {
        'circle-radius': 16,
        'circle-fill-color': 'rgba(0, 0, 0, 0.1)',
        'circle-stroke-color': 'yellow',
        'circle-stroke-width': 2,
      },
    });

    /**
     * @param {Feature} feature
     */
    const editFeature = (feature) => {
      if (feature) {
        if (editorMode.value.mode !== 'delete') {
          modifySource.addFeature(feature);
        }
        const { geometry, ...properties } = feature.getProperties();
        editableObject.value = {
          ...properties,
          geometry: wktFormat.writeGeometry(geometry),
        };
        editableObjectId.value = feature.getId();
        editorMode.value.snack = false;
      }
    };

    /**
     * @param {import('ol/interaction/Modify.js').ModifyEvent} event
     */
    const updateEditableObject = (event) => {
      const eventFeature = event.features.item(0);
      const wkt = wktFormat.writeGeometry(eventFeature.getGeometry());
      editableObject.value.geometry = wkt;
    };

    const userAllowedToEdit = (feature) =>
      editorMode.value.mode === 'edit' && feature.get('_userCanUpdate');
    const userAllowedToDelete = (feature) =>
      editorMode.value.mode === 'delete' && feature.get('_userCanDelete');

    /**
     * @param {import('ol/Feature.js').FeatureLike} feature
     * @param {EditAction} editAction
     * @returns {boolean}
     */
    const canEdit = (feature, editAction) =>
      feature &&
      feature instanceof Feature &&
      strapiSources.value[source].hasFeature(feature) &&
      feature.get(editActionProperty[editAction]);

    /** @type {import('ol/interaction/Modify.js').default} */
    let modify = null;

    /**
     * @param {import('ol/MapBrowserEvent.js').default} event
     */
    const interaction = new Interaction({
      handleEvent: (event) => {
        if (event.type === 'pointermove' && !event.dragging) {
          if (modify) {
            map.getTargetElement().style.cursor = '';
            return false;
          }
          let feature = map.forEachFeatureAtPixel(event.pixel, (f) => f, {
            layerFilter: (layer) =>
              layer.getSource() === strapiSources.value[source],
          });
          if (
            feature &&
            !userAllowedToEdit(feature) &&
            !userAllowedToDelete(feature)
          ) {
            feature = undefined;
          }
          map.getTargetElement().style.cursor = feature ? 'pointer' : '';
          return false;
        }
        if (event.type !== 'click') {
          return true;
        }
        if (modify) {
          return false;
        }
        let feature = map.forEachFeatureAtPixel(
          event.pixel,
          (f) => (f instanceof Feature ? f : undefined),
          {
            layerFilter: (layer) =>
              layer.getSource() === strapiSources.value[source],
          }
        );
        if (
          feature &&
          !userAllowedToEdit(feature) &&
          !userAllowedToDelete(feature)
        ) {
          feature = undefined;
        }

        if (!feature && editorMode.value.mode === 'add') {
          feature = new Feature();
          feature.setGeometry(new Point(event.coordinate));
          strapiSources.value[source].addFeature(feature);
        }
        editFeature(feature);
        return false;
      },
    });

    const unwatch = watch(strapiSources, (value) => {
      if (!value?.[source]) {
        return;
      }
      value[source].on('clear', () => {
        modifySource.clear();
        unwatch();
      });
    });

    watch(
      editorMode,
      () => {
        if (editorMode.value.mode !== '') {
          map.addInteraction(interaction);
        }
        if (editorMode.value.mode === '') {
          map.removeInteraction(interaction);
        }
        if (
          editorMode.value.mode !== '' &&
          !editorMode.value.snack &&
          !modify
        ) {
          modifyLayer.setMap(map);
          modify = new Modify({
            source: modifySource,
          });
          modify.on('modifyend', updateEditableObject);
          map.addInteraction(modify);
        }
        if (
          (editorMode.value.mode === '' || editorMode.value.snack) &&
          modify
        ) {
          map.removeInteraction(modify);
          modify.un('modifyend', updateEditableObject);
          modify = null;
          modifyLayer.setMap(null);
        }
      },
      { deep: true }
    );

    const apiResource = source.replace(/^strapi:/, '');

    /** @type {import('vue').Ref<Array<File|Blob>>} */
    const documents = shallowRef(null);

    /**
     * @returns {Promise<string>}
     */
    const getDocumentDataUri = () =>
      new Promise((resolve) => {
        if (documents.value) {
          const reader = new FileReader();
          reader.onloadend = () => {
            resolve(/** @type {string} */ (reader.result));
          };
          reader.readAsDataURL(documents.value[0]);
        } else {
          resolve('');
        }
      });

    /**
     * @param {string} [email] Optional email for passwordless login
     * @returns {Promise<boolean>} Resolves when item was saved successfully
     */
    const saveItem = async (email) => {
      try {
        const method =
          typeof editableObjectId.value === 'number' ? 'PUT' : 'POST';

        if (email && !UserData.value.id) {
          const data = { ...editableObject.value };
          if (documents.value) {
            data.document = await getDocumentDataUri();
          }
          const success = await requestPasswordless(email, {
            [source]: { saveItem: data },
          });
          return success;
        }
        const requestData = { ...editableObject.value };
        if (typeof requestData.document === 'string') {
          documents.value = [dataURLToBlob(requestData.document)];
          delete requestData.document;
        }
        if (documents.value) {
          const formData = new FormData();
          formData.append('files', documents.value[0]);
          const res = await fetchWithToken(`${API_BASE}/upload`, {
            method: 'POST',
            body: formData,
          });
          const responseData = await res.json();
          if (requestData.document?.data) {
            await fetchWithToken(
              `${API_BASE}/upload/files/${requestData.document.data.id}`,
              { method: 'DELETE' }
            );
          }
          requestData.document = responseData[0].id;
        }
        const urlPostfix = method === 'PUT' ? `/${editableObjectId.value}` : '';
        const response = await fetchWithToken(
          `${API_BASE}/${apiResource}${urlPostfix}`,
          {
            headers: {
              'Content-Type': 'application/json',
            },
            method,
            body: JSON.stringify({ data: requestData }),
          }
        );
        return !!response.ok;
      } catch (error) {
        console.error(error); // eslint-disable-line no-console
        return false;
      }
    };

    /**
     * @param {string} [email] Optional email for passwordless login
     * @returns {Promise<boolean>} Resolves when item was saved successfully
     */
    const deleteItem = async (email) => {
      if (email && !UserData.value.id) {
        const success = await requestPasswordless(email, {
          [source]: { deleteItem: editableObjectId.value },
        });
        return success;
      }
      const method = 'DELETE';
      const response = await fetchWithToken(
        `${API_BASE}/${apiResource}/${editableObjectId.value}`,
        {
          method,
        }
      );
      return !!response.ok;
    };

    const verifyNextItem = () => {
      const feature = strapiSources.value[source]
        .getFeatures()
        .find((f) => f.get('freigegeben') === false && f.get('_userCanUpdate'));
      if (!feature) {
        editableObject.value = null;
        editorMode.value.mode = '';
        alert('Keine weiteren Anlagen zur Genehmigung vorhanden!'); // eslint-disable-line no-alert
        return;
      }
      modifySource.addFeature(feature);
      editableObject.value = {
        ...feature.getProperties(),
        geometry: wktFormat.writeGeometry(feature.getGeometry()),
      };
      editableObjectId.value = feature.getId();
    };

    const csvDownload = async () => {
      const fields = [
        'anlagenflaeche',
        'antragsdatum',
        'art',
        'createdAt',
        'datumrueckmeldung',
        'einspeiseart',
        'einspeiseleistung',
        'errichtungsart',
        'freigegeben',
        'nennleistung',
        'sonstiges',
        'stromspeicher',
        'stromspeicherkapazitaet',
      ];
      const res = await fetchWithToken(`${API_BASE}/anlagen`);
      const responseData = await res.json();
      const filtered = responseData.data.filter(
        // eslint-disable-next-line no-underscore-dangle
        (elem) => elem.attributes._userCanDelete
      );

      const exportArray = [];
      for (let i = 0; i < filtered.length; i++) {
        exportArray.push(filtered[i].attributes);
      }
      try {
        const opts = { fields, delimiter: ';' };
        const parser = new Parser(opts);
        const csv = parser.parse(exportArray);

        const BOM = '\uFEFF';
        const blob = new Blob([BOM + csv], {
          type: 'text/csv;charset=utf-8;',
        });

        const url = URL.createObjectURL(blob);

        // Create a link to download it
        const pom = document.createElement('a');
        pom.href = url;
        pom.setAttribute('download', 'anlagen.csv');
        pom.click();
      } catch (err) {
        return err;
      }
      return true;
    };

    composableData[source] = {
      canEdit,
      editFeature,
      editableObject,
      editableObjectId,
      editorMode,
      documents,
      saveItem,
      deleteItem,
      verifyNextItem,
      csvDownload,
    };
  }
  return {
    ...composableData[source],
  };
}
