<template>
  <div v-html="markup" />
</template>

<script setup>
import { ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import MarkdownIt from 'markdown-it';
import { API_BASE, MARKDOWN_CONFIG, PUBLICATION_STATE } from '../constants.js';

const markup = ref('');
const markdown = new MarkdownIt(MARKDOWN_CONFIG);

const route = useRoute();

const props = defineProps({
  pagecontent: {
    type: String,
    default: '',
    required: true,
  },
});

watch(
  () => route.params.pagecontent,
  async () => {
    markup.value = '';
    if (route.params.pagecontent && route.params.pagecontent !== '') {
      const response = await fetch(
        `${API_BASE}/inhalte?publicationState=${PUBLICATION_STATE}&filters[key][$eq]=${route.params.pagecontent}`
      );
      const data = await response.json();
      markup.value = markdown.render(data.data[0].attributes.Text);
    }
  },
  { immediate: true }
);

watch(
  () => props.pagecontent,
  async () => {
    markup.value = '';
    if (props.pagecontent && props.pagecontent !== '') {
      const response = await fetch(
        `${API_BASE}/inhalte?publicationState=${PUBLICATION_STATE}&filters[key][$eq]=${props.pagecontent}`
      );
      const data = await response.json();
      markup.value = markdown.render(data.data[0].attributes.Text);
    }
  },
  { immediate: true }
);
</script>
