import { ref } from 'vue';
import { API_BASE } from '../constants.js';

/**
 * @typedef {Object} UserDataContent
 * @property {Number} [id]
 * @property {String} [username]
 * @property {String} [email]
 * @property {String} [provider]
 * @property {Boolean} [confirmed]
 * @property {Boolean} [blocked]
 * @property {Date} [createdAt]
 * @property {Date} [updatedAt]
 * @property {String} [uid]
 * @property {Object} [gemeinde]
 */

/** @type {import('vue').Ref<UserDataContent>} */
export const UserData = ref({});

const isSecure =
  new URL(import.meta.env.VITE_API_BASE, window.location.href).protocol ===
  'https:';

/** @returns {string} */
export const getToken = () => {
  if (!document.cookie) {
    return null;
  }
  const jwt = document.cookie.match(
    /(?:(?:^|.*;\s*)token\s*=\s*([^;]*).*$)|^.*$/
  )[1];
  return jwt || null;
};

export function fetchWithToken(input, init) {
  const token = getToken();
  return token
    ? fetch(input, {
        ...init,
        headers: {
          Accept: 'application/json',
          ...init?.headers,
          Authorization: `Bearer ${token}`,
        },
      })
    : fetch(input, init);
}

function parseJwt(token) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join('')
  );

  return JSON.parse(jsonPayload);
}

export function setJwtCookie(jwt) {
  const secure = isSecure ? ' secure;' : ' ';
  if (!jwt) {
    document.cookie = `token=;${secure} expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    return;
  }
  const jwtValues = parseJwt(jwt);
  const expireDate = new Date(0);
  expireDate.setUTCSeconds(jwtValues.exp - 24 * 3600);
  document.cookie = `token=${jwt};${secure} expires=${expireDate.toUTCString()}; path=/;`;
}

/**
 * Checks if user for existing token exists and retrieves data
 * @return {Promise<void>} Resolves when UserData is populated
 */
export async function userInit() {
  if (!getToken()) {
    return;
  }
  await fetchWithToken(`${API_BASE}/users/me?populate=*`)
    .then((response) => response.json())
    .then((data) => {
      if (data && data.email) {
        UserData.value = data;
      }
    });
}

/**
 * Request a passwordless login link
 * @param {string} email
 * @param {Object} [context] Optional context object
 * @returns {Promise<boolean>} Resolves when the request was successful
 */
export async function requestPasswordless(email, context) {
  try {
    const response = await fetch(`${API_BASE}/passwordless/send-link`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({ email, context }),
    });
    return response.ok;
  } catch (error) {
    console.error(error); // eslint-disable-line no-console
  }
  return false;
}

/**
 * Login Site User
 * @param {String} useridt
 * @param {String} userpw
 * @returns {Promise<boolean>} Resolves when user is logged in or if an error occurs
 */
export async function login(useridt, userpw) {
  if (!userpw) {
    // passwordless login
  }
  let retval = false;
  try {
    const response = await fetch(`${API_BASE}/auth/local`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({ identifier: useridt, password: userpw }),
    });
    const data = await response.json();
    if (data.jwt && data.user) {
      setJwtCookie(data.jwt);
      await userInit();
      retval = true;
    }
  } catch (error) {
    console.error(error); // eslint-disable-line no-console
  }
  return retval;
}

/**
 * Logs out the user (by deleting the JWT Token Cookie for now)
 * @return {Promise<void>} Resolves when cookie and UserData are deleted
 */
export async function logout() {
  setJwtCookie('');
  UserData.value = {};
}

/** @type {import('vue').Ref<Boolean>} */
export const disclaimerOnce = ref(true);

/** @type {import('vue').Ref<Boolean>} */
export const showLogin = ref(false);

export const useUserManage = () => ({
  userInit,
  login,
  logout,
  UserData,
  fetchWithToken,
  requestPasswordless,
  setJwtCookie,
  disclaimerOnce,
  showLogin,
});
