import { ref, watch, shallowRef, computed } from 'vue';
import LayerGroup from 'ol/layer/Group.js';
import { Group } from 'ol/layer';
import { apply, renderTransparent } from 'ol-mapbox-style';
import VectorTileLayer from 'ol/layer/VectorTile';
import Layer from 'ol/layer/Layer';
import { WKT, GeoJSON } from 'ol/format';
import { VectorTile as VectorTileSource } from 'ol/source';
import { API_BASE, PUBLICATION_STATE } from '../constants.js';
import { fetchWithToken, UserData } from './useUserManage.js';

/**
 * @typedef {Object} MapboxStyle
 * @property {string} version
 * @property {string} id
 * @property {string} sprite
 * @property {string} glyphs
 * @property {Array<Object>} layers
 * @property {Object<string, Object>} sources
 */

/**
 * @typedef {Object} Schlagwort
 * @property {number} id
 * @property {SchlagwortAttributes} attributes
 */

/**
 * @typedef {Object} SchlagwortAttributes
 * @property {number} rank
 * @property {string} schlagwort
 */

/**
 * @typedef {Object} Karte
 * @property {number} id
 * @property {KarteAttributes} attributes
 */

/**
 * @typedef {Object} KarteAttributes
 * @property {string} name
 * @property {MapboxStyle} stylejson
 * @property {string} anmerkung
 * @property {{data?: import('../constants').Media}} thumbnail
 * @property {string} metadaten_beschreibung
 * @property {string} metadaten_raeumlicheranwendungsbereich
 * @property {string} metadaten_sprache
 * @property {string} metadaten_darstellungsart
 * @property {string} metadaten_zoomfaktormaszstab
 * @property {string} metadaten_herkunft
 * @property {string} metadaten_kontakt
 * @property {string} metadaten_aktualisierung
 * @property {string} infotemplate
 * @property {{data: import('../constants').Media}} legendenbild
 * @property {{data: Array<Schlagwort>}} metadaten_schlagwoerter
 * @property {{data: import('./useKategorien.js').Kategorie}} metadaten_kategorie
 * @property {string} metadaten_zusatzinformationen
 * @property {Date} metadaten_veroeffentlichung
 * @property {string} ressource_kontakt
 * @property {number} minzoom_hint
 * @property {number} maxzoom_hint
 */

/**
 * @typedef {Object} Kartenansicht
 * @property {number} id
 * @property {KartenansichtAttributes} attributes
 */

/**
 * @typedef {Object} KartenansichtAttributes
 * @property {string} name
 * @property {{data: Array<Karte>}} fachkarten
 * @property {{data: Array<Karte>}} basiskarten
 * @property {string} kategorie
 * @property {Blob}  thumbnail_image
 * @property {Blob} legende_image
 * @property {string} beschreibung
 * @property {string} tags
 * @property {{data: import('../constants').Media}} thumbnail
 */

/**
 * @typedef {Object} Kartendetails
 * @property {boolean} visible
 * @property {KarteAttributes} attributes
 */

const wktFormat = new WKT();
const geojsonFormat = new GeoJSON();

/**
 * This is the leading Ref of this composable. It will be updated after `selectedBaselayerIndex`
 * and `kartendetails` when a new kartenansicht is loaded.
 * @type {import('vue').Ref<Kartenansicht>}
 */
export const kartenansicht = shallowRef(null);

/**
 * Will be true when there was an error loading kategorien
 * @type {import("vue").Ref<boolean>}
 */
export const error = ref(false);

/** @type {import('vue').Ref<number>} */
const selectedBaselayerIndex = ref(-1);

/** @type {import('vue').Ref<number>} */
export const opacity = ref(0.9);

/** @type {import('vue').Ref<Array<Kartendetails>>} */
export const kartendetails = ref([]);

/** @type {import('vue').Ref<string>} */
export const currentKartenansichtId = ref(undefined);

/** @type {import('vue').Ref<number>} */
export const fachkarteZoom = ref(0);
/** @type {import('vue').Ref<Array<number>>} */
export const fachkarteCenter = ref([0, 0]);

/** @type {import('vue').ShallowRef<{[key: string]: import("ol/source/Vector.js").default}>} */
export const strapiSources = shallowRef({});

/** @type {import('vue').ComputedRef<string>} */
export const visibleLayersRouteParam = computed(() => {
  if (!kartenansicht.value || selectedBaselayerIndex.value === -1) {
    return undefined;
  }
  const visibleLayers = [
    selectedBaselayerIndex.value,
    ',',
    ...kartendetails.value.map((kartendetail) =>
      kartendetail.visible ? '1' : '0'
    ),
  ].join('');
  return visibleLayers;
});

/** @type {LayerGroup} */
export const fachkarteGroup = new LayerGroup();

/** @type {LayerGroup} */
export const baselayerGroup = new LayerGroup();

renderTransparent(true);

const onTileloadstart = (e) => {
  const source = e.target;
  source.set('tilesloading', (source.get('tilesloading') || 0) + 1);
};
const onTileloadend = (e) => {
  const source = e.target;
  source.set('tilesloading', source.get('tilesloading') - 1);
};

const addStrapiSource = (url, mapboxSources, layers) => {
  const mapboxSource =
    Object.keys(mapboxSources)[
      Object.values(mapboxSources).findIndex((source) => source.data === url)
    ];
  strapiSources.value = {
    ...strapiSources.value,
    [url]: layers
      .find((layer) => layer.get('mapbox-source') === mapboxSource)
      .getSource(),
  };
};

const collectionToGeoJSON = async (url) => {
  const strapiUrl = new URL(
    url.replace(/^strapi:/, `${API_BASE}/`),
    window.location.href
  );
  const request = await fetchWithToken(strapiUrl.toString());
  if (!request.ok) {
    throw new Error(`Failed to fetch ${url}`);
  }
  const json = await request.json();
  const geojson = {
    type: 'FeatureCollection',
    features: json.data.map(
      ({ id: fid, attributes: { geometry, ...properties } }) => ({
        id: fid,
        type: 'Feature',
        geometry: geojsonFormat.writeGeometryObject(
          wktFormat.readGeometry(geometry)
        ),
        properties,
      })
    ),
  };
  const blob = new Blob([JSON.stringify(geojson)], {
    type: 'application/json',
  });
  const objectUrl = URL.createObjectURL(blob);
  setTimeout(() => URL.revokeObjectURL(objectUrl), 10000);
  return objectUrl;
};

/**
 * @param {import("ol/layer/Group.js").Options} options
 * @returns {LayerGroup}
 */
function createSubLayerGroup(options) {
  const subLayerGroup = new LayerGroup(options);
  subLayerGroup.getLayers().on('add', async (event) => {
    const layer = event.element;
    /** @type {import("ol/source/Source.js").default} */
    const source = await new Promise((resolve) => {
      if (!(layer instanceof Layer)) {
        resolve(null);
      } else if (layer.getSource()) {
        resolve(layer.getSource());
      } else {
        layer.once('change:source', () => resolve(layer.getSource()));
      }
    });
    if (source instanceof VectorTileSource) {
      source.on('tileloadstart', onTileloadstart);
      source.on(['tileloadend', 'tileloaderror'], onTileloadend);
    }
  });
  subLayerGroup.getLayers().on('remove', (event) => {
    const layer = event.element;
    if (layer instanceof VectorTileLayer) {
      layer.getSource().un('tileloadstart', onTileloadstart);
      layer.getSource().un(['tileloadend', 'tileloaderror'], onTileloadend);
    }
  });
  return subLayerGroup;
}

/**
 * Update the visible layers
 * @param {string} visibleLayers visibleLayers route param string
 * @return {Promise<void>} Resolves when the kartenansicht is fully updated
 */
export function updateVisibleLayers(visibleLayers) {
  return new Promise((resolve) => {
    if (kartenansicht.value === null) {
      const unwatch = watch([kartenansicht, error], async () => {
        unwatch();
        if (!error.value) {
          await updateVisibleLayers(visibleLayers);
        }
        resolve();
      });
      return;
    }
    if (!visibleLayers) {
      // Came from route without visibleLayers set, update them
      visibleLayers = visibleLayersRouteParam.value;
    }
    const [baselayerIndex, kartendetailsVisible] = visibleLayers.split(',');
    selectedBaselayerIndex.value = Number(baselayerIndex);
    kartendetails.value.forEach((kartendetail, index) => {
      kartendetail.visible = kartendetailsVisible.charAt(index) === '1';
    });
    resolve();
  });
}

fachkarteGroup.getLayers().on('remove', (event) => {
  const layer = event.element;
  if (layer instanceof LayerGroup) {
    layer.getLayers().clear();
  }
});

watch(
  kartendetails,
  (value) => {
    value.forEach((detail, index) => {
      const layerGroup = fachkarteGroup.getLayers().item(index + 1);
      if (detail.visible !== layerGroup.getVisible()) {
        layerGroup.setVisible(detail.visible);
      }
    });
  },
  { deep: true }
);

export const mapIDs = (async () => {
  const response = await fetch(
    `${API_BASE}/kartenansichten?publicationState=${PUBLICATION_STATE}`
  );
  const data = await response.json();
  return data.data.map((item) => item.id);
})();

/**
 * when the ID of the kartenansicht changes, fetch the associated fachkarte and basiskarten
 */
watch([currentKartenansichtId, UserData], async ([id, user]) => {
  if (id === undefined) {
    return;
  }
  if (id === '0') {
    if (user.gemeinde) {
      id = String((await mapIDs)[1]);
    } else if (!user.gemeinde) {
      id = String((await mapIDs)[0]);
    }
  }
  error.value = false;
  const previousBasiskarteId =
    kartenansicht.value?.attributes.basiskarten.data[
      selectedBaselayerIndex.value
    ]?.id;
  kartendetails.value = [];
  selectedBaselayerIndex.value = -1;
  kartenansicht.value = null;
  baselayerGroup.getLayers().clear();
  fachkarteGroup.getLayers().clear();

  try {
    const response = await fetch(
      `${API_BASE}/kartenansichten/${id}?publicationState=${PUBLICATION_STATE}&populate[0]=basiskarten.thumbnail&populate[1]=fachkarten.thumbnail&populate[2]=fachkarten.metadaten_kategorie&populate[3]=fachkarten.metadaten_schlagwoerter&populate[4]=fachkarten.legendenbild&locale=de`
    );

    if (!response.ok) {
      // kartenansicht is not available anymore
      error.value = true;
      return;
    }
    const newKartenansicht = (await response.json()).data;
    const newKartendetails = [];

    const fachkarten = newKartenansicht.attributes.fachkarten.data;
    await Promise.all(
      fachkarten.map((fachkarte, index) => {
        const group = createSubLayerGroup({
          visible: index === 0,
        });
        group.set('name', fachkarte.attributes.name);
        fachkarteGroup.getLayers().push(group);
        const { stylejson } = fachkarte.attributes;
        if (index > 0) {
          newKartendetails.push({
            visible: false,
            attributes: fachkarte.attributes,
          });
        } else {
          if (stylejson.zoom !== undefined) {
            fachkarteZoom.value = stylejson.zoom;
          }
          if (stylejson.center !== undefined) {
            fachkarteCenter.value = stylejson.center;
          }
        }
        return apply(group, stylejson, {
          transformRequest: async (url, type) => {
            if (type === 'GeoJSON' && url.startsWith('strapi:')) {
              addStrapiSource(url, stylejson.sources, group.getLayersArray());
              return collectionToGeoJSON(url);
            }
            return url;
          },
        });
      })
    );
    kartendetails.value = newKartendetails;

    const baselayerGroupLayers = baselayerGroup.getLayers();
    newKartenansicht.attributes.basiskarten.data.forEach((basiskartenInfo) => {
      const group = new Group({ visible: false });
      baselayerGroupLayers.push(group);
      apply(group, basiskartenInfo.attributes.stylejson);
    });

    const indexOfPreviousBaselayer =
      previousBasiskarteId === undefined
        ? 0
        : newKartenansicht.attributes.basiskarten.data.findIndex(
            (basiskarte) => basiskarte.id === previousBasiskarteId
          );
    selectedBaselayerIndex.value =
      indexOfPreviousBaselayer === -1 ? 0 : indexOfPreviousBaselayer;
    kartenansicht.value = newKartenansicht;
  } catch (e) {
    console.error(e); // eslint-disable-line no-console
  }
});

const initialSearchParams = new URL(window.location.href).searchParams;

/** @type {import("vue").Ref<{x: string, y: string, z: string}>} */
export const xyzQuery = ref({
  x: initialSearchParams.get('x') || '0',
  y: initialSearchParams.get('y') || '0',
  z: initialSearchParams.get('z') || '0',
});

let userModified = false;
watch(selectedBaselayerIndex, (index) => {
  const currentBaseLayers = baselayerGroup.getLayers();
  currentBaseLayers.forEach((l, i) => {
    l.setVisible(i === index);
  });
  if (!kartenansicht.value) {
    return;
  }
  const minZoom =
    kartenansicht.value.attributes.basiskarten.data[index].attributes
      .minzoom_hint;
  const maxZoom =
    kartenansicht.value.attributes.basiskarten.data[index].attributes
      .maxzoom_hint;
  const zoom = Number(xyzQuery.value.z);
  if (zoom < minZoom || zoom >= maxZoom) {
    userModified = true;
  }
});

watch(xyzQuery, ({ z }) => {
  if (!kartenansicht.value) {
    return;
  }
  const zoom = Number(z);
  const minZoom =
    kartenansicht.value.attributes.basiskarten.data[
      selectedBaselayerIndex.value
    ].attributes.minzoom_hint;
  const maxZoom =
    kartenansicht.value.attributes.basiskarten.data[
      selectedBaselayerIndex.value
    ].attributes.maxzoom_hint;
  if (zoom >= minZoom && zoom < maxZoom) {
    userModified = false;
  }
  if (!userModified) {
    const basiskarten = kartenansicht.value.attributes.basiskarten.data;
    for (let i = 0, ii = basiskarten.length; i < ii; i++) {
      const basiskarte = basiskarten[i];
      if (
        zoom >= basiskarte.attributes.minzoom_hint &&
        zoom < basiskarte.attributes.maxzoom_hint
      ) {
        selectedBaselayerIndex.value = i;
        break;
      }
    }
  }
});

watch(
  opacity,
  (value) => {
    fachkarteGroup.setOpacity(value);
  },
  { immediate: true }
);

export function useLayers() {
  return {
    selectedBaselayerIndex,
    opacity,
    kartenansicht,
    kartendetails,
    visibleLayersRouteParam,
    fachkarteGroup,
    fachkarteZoom,
    fachkarteCenter,
    strapiSources,
  };
}
