/**
 * @typedef {Object} MediaAttributes
 * @property {string} name
 * @property {string} url
 * @property {number} width
 * @property {number} height
 */

/**
 * @typedef {Object} Media
 * @property {MediaAttributes} attributes
 */

export const API_BASE =
  import.meta.env.VITE_API_BASE || 'https://pv-suedweststeiermark.w3geo.at/api';
export const REFERENCE_SCREEN_SIZE = [1280, 1024];
export const MARKDOWN_CONFIG = {
  html: true,
  linkify: true,
  breaks: true,
};

export const PUBLICATION_STATE = new URL(window.location.href).searchParams.has(
  'preview'
)
  ? 'preview'
  : 'live';

export const HTML2CANVAS_SCALE = window.screen.width < 800 ? 1 : 2;

export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const editorHints = {
  add: 'Klicken Sie in der Karte auf die Position der Anlage, um einen Eintrag zu erstellen. Nutzen Sie dazu gegebenenfalls die Ortssuche rechts oben.',
  delete:
    'Klicken Sie in der Karte auf die Anlage, die gelöscht werden soll. Sie können nur Anlagen löschen, zu deren Bearbeitung Sie berechtigt sind.',
  edit: 'Klicken Sie in der Karte auf eine Anlage, um den Eintrag zu bearbeiten. Sie können nur Anlagen in Ihrer Region bearbeiten.',
  '': '',
};
