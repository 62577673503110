const AppBar = () => import('./components/AppBar.vue');
const Information = () => import('./components/Information.vue');
const InformationContainer = () => import('./views/InformationContainer.vue');
const Map = () => import('./views/Map.vue');
const MapRouteTitle = () => import('./components/MapRouteTitle.vue');
const TheDisclaimer = () => import('./components/TheDisclaimer.vue');
const TheMapSearch = () => import('./components/TheMapSearch.vue');
const TheNavigationRail = () => import('./components/TheNavigationRail.vue');
const TheLogin = () => import('./components/TheLogin.vue');
const TheEmailLoginInfo = () => import('./components/TheEmailLoginInfo.vue');

/** @type {Array<import("vue-router").RouteRecordRaw>} */
export default [
  { path: '/', redirect: 'map/0' },
  {
    path: '/map/:kartenansichtId/:visibleLayers?',
    components: {
      default: Map,
      AppBar,
      TheNavigationRail,
      TheDisclaimer,
      TheLogin,
    },
    children: [
      {
        name: 'Map',
        path: '',
        components: {
          title: MapRouteTitle,
          search: TheMapSearch,
        },
      },
    ],
  },
  {
    path: '/information',
    name: 'Information',
    components: {
      default: InformationContainer,
      AppBar,
      TheNavigationRail,
      TheLogin,
    },
    children: [
      {
        path: ':pagecontent',
        component: Information,
      },
    ],
  },
  {
    path: '/linksent',
    name: 'Linksent',
    components: {
      default: TheEmailLoginInfo,
    },
  },
];
