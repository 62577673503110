import { ref } from 'vue';
import { API_BASE } from '../constants.js';
import { useEditorMode } from './useEditorMode.js';
import { setJwtCookie, userInit } from './useUserManage.js';

const loginWarning = ref(false);

/**
 * @param {string} token
 * @returns {Promise<boolean>}
 */
export async function loginPasswordless(token) {
  const safeToken = encodeURIComponent(token);
  try {
    const response = await fetch(
      `${API_BASE}/passwordless/login?loginToken=${safeToken}`
    );
    const data = await response.json();
    if (data.jwt) {
      setJwtCookie(data.jwt);
      await userInit();
      if (data.context) {
        for (const key in data.context) {
          const { saveItem, deleteItem, editableObject, editableObjectId } =
            useEditorMode(key);
          editableObject.value = data.context[key].saveItem;
          editableObjectId.value = data.context[key].deleteItem;
          if (editableObject.value) {
            await saveItem(); // eslint-disable-line no-await-in-loop
          }
          if (editableObjectId.value) {
            await deleteItem(); // eslint-disable-line no-await-in-loop
          }
        }
      }
      return true;
    }
  } catch (error) {
    console.error(error); // eslint-disable-line no-console
  }
  return false;
}

(async () => {
  if (!window.location.search) {
    return;
  }
  const url = new URL(window.location.href);
  loginWarning.value = false;
  const loginToken = url.searchParams.get('loginToken');
  if (loginToken) {
    url.searchParams.delete('loginToken');
    window.history.replaceState({}, '', url);
    const success = await loginPasswordless(loginToken.toString());
    if (!success) {
      loginWarning.value = true;
    }
  }
})();

export const usePasswordless = () => ({
  loginPasswordless,
  loginWarning,
});
