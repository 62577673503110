<template>
  <v-snackbar
    :model-value="editorMode.snack"
    timeout="-1"
    color="rgb(117, 163, 165)"
    location="top"
    class="mySnack"
    >{{ editorHints[editorMode.mode] }}
    <template #actions>
      <v-btn
        prepend-icon="mdi-close"
        density="compact"
        class="ml-3"
        color="white"
        variant="outlined"
        @click="(editorMode.snack = false), (editorMode.mode = '')"
      >
        Abbrechen
      </v-btn>
    </template></v-snackbar
  >
</template>

<script setup>
import { useEditorMode } from '../composables/useEditorMode.js';
import { editorHints } from '../constants.js';

const { editorMode } = useEditorMode('strapi:anlagen');
</script>

<style>
.mySnack div.v-snackbar__content {
  border-right: 1px solid rgba(255, 255, 255, 0.5);
}
</style>
